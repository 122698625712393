<template>
  <!-- MODAL MAILS FRAIS -->

  <v-dialog
    v-model="dialog"
    max-width="1800px"
    hide-overlay
    persistent
    content-class="custom-vuetify-dialog-header-envoi-email"
  >
    <v-card>
      <v-card-title class="text-h6 title-modal">
        <v-label class="label-header"
          >Email à envoyer par {{ computedName }}</v-label
        >
        <div class="actionModelMailVendeur d-flex justify-content-center mb-1">
          <v-btn
            color="#704ad1"
            class="btn-circle mr-2"
            outlined
            @click="previous()"
            v-if="index > 0 && index < dataToUse.length"
          >
            Précédent
          </v-btn>
          <span
            v-for="(i, indexVendeur) in dataToUse"
            :key="'indexVendeur' + indexVendeur"
            class="circleStep"
            :class="{
              circleStepActive: indexVendeur == index,
              circleStepSend: dataToUse[indexVendeur].mailSend == true
            }"
          ></span>
          <v-btn
            color="#704ad1"
            class="btn-circle ml-2"
            outlined
            v-if="index < dataToUse.length - 1 && index >= 0"
            @click="next()"
          >
            Suivant
          </v-btn>
        </div>
        <v-btn
          class="btn-close-header"
          icon
          @click="$emit('custumChange')"
          title="Fermer"
          color="#704ad1"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="line-hr-header"></v-divider>
      <v-card-text class="body-card center-text  mt-2">
        <div
          v-if="getLoaderSendMailCdg == true"
          class="loading-custom-template-block-envoi-email-vendeur-oblige"
        >
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#704ad1'"
            class="loeder"
          />
        </div>

        <div
          v-if="getLoadingMailDataCdg == true"
          class="loading-custom-template-block-envoi-email-vendeur-oblige"
        >
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#704ad1'"
            class="loeder"
          />
        </div>
        <div class="mail-vendeur-box">
          <div class="mail-vendeur-content">
            <template-mail
              @eventMailSend="
                dataToUse[index].updateMailSend(true);
                $emit('eventMailSendRefresh');
              "
              :changeVendeurIndex="changeVendeurIndex"
              :dataToUse="dataToUse[index]"
              v-if="dataToUse && dataToUse[index]"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  <!-- END MODAL MAILS FRAIS -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    isOpen: { required: true }
  },
  data() {
    return {
      index: 0,
      changeVendeurIndex: true,
      dialog: false
    };
  },
  computed: {
    ...mapGetters(['getLoadingMailDataCdg', 'getLoaderSendMailCdg']),
    computedDisabledSend() {
      return true;
    },
    computedName() {
      return this.dataToUse &&
        this.dataToUse[this.index] &&
        this.dataToUse[this.index].name_vendeur
        ? this.dataToUse[this.index].name_vendeur
        : '';
    }
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.$emit('custumChange');
    },
    previous() {
      this.changeVendeurIndex = !this.changeVendeurIndex;
      this.index = this.index - 1;
    },
    next() {
      this.changeVendeurIndex = !this.changeVendeurIndex;
      this.index = this.index + 1;
    }
  },
  watch: {
    isOpen: function() {
      if (this.isOpen == true) {
        this.dialog = true;
      } else {
        this.dialog = false;
      }
    }
  },
  components: {
    templateMail: () => import('./templateMail.vue')
  }
};
</script>

<style scoped lang="scss">
.loading-custom-template-block-envoi-email-vendeur-oblige {
  position: absolute;
  height: 100%;
  top: 9px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding-top: 21%;
  padding-left: 642px;
  background-color: #ffffff87;
  z-index: 10;
}
.mail-vendeur-box {
  height: calc(100vh - 78px);
  .mail-vendeur-content {
    height: calc(100vh - 126px);
  }
}
.actionModelMailVendeur {
  align-items: center;
  .btn-circle {
    height: 28px;
    min-width: 64px;
    padding: 5px 8px;
    margin-bottom: -6px;
  }
  .button-valide-style {
    &:active {
      height: auto;
    }
  }
}
</style>
<style lang="scss">
.send-mail-component-cdg-modal {
  .modal-header,
  .modal-body {
    padding: 0.5rem;
  }
}
</style>
